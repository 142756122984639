import "./instrumentation";

import { ErrorBoundary } from "@sentry/react";
import React, { type ComponentType, Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Route, Router } from "wouter";
import RootLayout from "./app/layout";
import { useLocationWithConfirmation } from "./hooks/navigation/useLocationWithConfirmation";
import { PostHogProvider } from "posthog-js/react";
import posthog from "./lib/posthog";

const pages = import.meta.glob("./app/**/page.tsx") as Record<string, () => Promise<{ default: ComponentType }>>;

const AppMapping = Object.entries(pages)
	.filter(([path]) => !path.includes("["))
	.map(([path, component]) => ({
		// remove "./app/" and "/page.tsx"
		path: "/" + path.split("/").slice(2, -1).join("/"),
		Component: lazy(component),
	}));

const root = document.getElementById("root")!;

const Root = () => {
	// Get PostHog configuration
	const posthogApiKey = import.meta.env.VITE_POSTHOG_API_KEY as string;
	const posthogHost = (import.meta.env.VITE_POSTHOG_HOST as string) || "https://app.posthog.com";
	const posthogEnabled = import.meta.env.VITE_POSTHOG_ENABLED !== "false";

	// PostHog options
	const posthogOptions = {
		api_host: posthogHost,
		loaded: (loadedPosthog: typeof posthog) => {
			if (import.meta.env.DEV) {
				loadedPosthog.debug();
			}
		},
		capture_pageview: true,
		enable: posthogEnabled,
	};

	return (
		<React.StrictMode>
			<ErrorBoundary fallback={<div>An error occurred. Our team has been notified.</div>} showDialog>
				<PostHogProvider apiKey={posthogApiKey} options={posthogOptions}>
					<RootLayout>
						<Router hook={useLocationWithConfirmation}>
							{AppMapping.map(({ path, Component }) => (
								<Route key={path} path={path}>
									<Suspense fallback={<div></div>}>
										<Component />
									</Suspense>
								</Route>
							))}
						</Router>
					</RootLayout>
				</PostHogProvider>
			</ErrorBoundary>
		</React.StrictMode>
	);
};

createRoot(root).render(<Root />);

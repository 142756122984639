import { BaseLocationHook } from "wouter";
import { useBrowserLocation } from "wouter/use-browser-location";
import { relativePath } from "./paths";
import { useCallback } from "react";
import { useEffect } from "react";
import useDirtyStore from "@/store/useDirtyStore";

export const useLocationWithConfirmation: BaseLocationHook = router => {
	const [location, navigate] = useBrowserLocation();
	const { isDirty, setIsDirty, setIsSaving } = useDirtyStore();
	const beforeunloadHandler = useCallback(
		function (e: BeforeUnloadEvent) {
			if (isDirty) {
				e.preventDefault();
				// Deprecated, but still required for some browsers, per MDN:
				// https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent/returnValue
				e.returnValue = "";
			}
		},
		[isDirty],
	);

	useEffect(() => {
		window.addEventListener("beforeunload", beforeunloadHandler);

		return () => {
			window.removeEventListener("beforeunload", beforeunloadHandler);
		};
	}, [beforeunloadHandler]);

	return [
		relativePath(router.base, location),
		newLocation => {
			let perfomNavigation = true;
			if (isDirty) {
				perfomNavigation = window.confirm("You have unsaved changes. Are you sure you want to leave?");
			}

			if (perfomNavigation) {
				navigate(newLocation);
				setIsSaving(false);
				setIsDirty(false);
			}
		},
	];
};
